import { useHistory, useParams } from "react-router-dom";
import { Card, CardImg, CardImgOverlay, CardText, Col, Container, Row } from "reactstrap";
import { getCollectives } from "./services/Mitglieder";

import placeholderColl from './img/800x180.png'
import { useDocumentDataOnce, useDocumentOnce } from "react-firebase-hooks/firestore";
import { StimmexEvent } from "./services/Events";
import { firestore, storage } from "./firebase";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { auto } from "@popperjs/core";


const Event: React.FC<{ setName: (name: string) => void }> = ({setName}) => {
    const { eventId } = useParams<{ eventId: string }>();
    const history = useHistory();
    const [event] = useDocumentDataOnce<StimmexEvent>(firestore.doc(`/events/${eventId}`))

    const [imageUrl] = useDownloadURL(
        storage.ref(event?.picturePath)
    );

    setName(event?.start.toDate().toLocaleDateString("de-DE"))


    return (<div>

        <Row>

            <Col sm="12">
                <a href="#" onClick={() => history.push("/save-the-date")}>{"<<<<"}</a>
            </Col>
            {/* <Col sm="12">
                <img src={imageUrl} style={{ width:"100%" }}></img>
               

            </Col> */}

            <Col sm="12">
            {/* <Card style={{cursor:"default" }} >
                <CardImg src={imageUrl} className="h-100" style={{objectFit: "scale-down"}} ></CardImg>
                { event && event.photoCopyright && <CardImgOverlay className=" h-100 p-0 d-flex flex-column justify-content-end"><CardText className=" copyright p-1"><strong> © {event.photoCopyright} </strong></CardText></CardImgOverlay>}
            </Card> */}

            <img className="image-responsive" src={imageUrl} style={{maxHeight:'500px', width:'auto', objectFit:'scale-down'}}></img>
            {event && event.photoCopyright && <p className="copyright"> © {event.photoCopyright}</p>}
            <br/>

            {/* <img src={member?.pictureUrl} style={{height:"290px", width:"250px", objectFit: "cover", marginTop:"30px", marginBottom:"30px" }}></img> */}
        </Col>
            <Col sm="12">
                <h3>{event?.title}</h3>

            </Col>
            <Col sm="12">
                <h5>{event?.start.toDate().toLocaleDateString("de-DE")}  {event?.start.toDate().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} </h5>

            </Col>

            <Col sm="12">
                <div dangerouslySetInnerHTML={{__html: event? event.description : ""}}></div>

                <br/><br/><br/><br/><br/><br/><br/><br/>




            </Col>


        </Row>




    </div>)
}

export default Event;


