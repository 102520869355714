import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { getMembers, Member } from "./services/Mitglieder";

import placeholderImg from './img/placeholder2.jpg'
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { firestore } from "./firebase";
import Mitglied from "./Mitglied";


const MitgliedPage: React.FC<{onNameReady: (name:string)=>void, backPath?: string, backLable?:string, onNavigateAway: (targetPath: string)=> void}> = ({onNameReady, backPath="/mitglieder", backLable="Mitglieder", onNavigateAway}) => {

    const { mitgliedId: mitgliedId } = useParams<{ mitgliedId: string }>();
    const history = useHistory();

    const [member] = useDocumentDataOnce<Member>(firestore.doc(`/sections/mitglieder/mitglieder/${mitgliedId}`))

    if(member && member.name){
        onNameReady(`${member.name } ${member.lastname}`);
    }

    return (
        
        <Container>
            {member && (
                <Mitglied member={member} backPath={backPath} backLable={backLable} onNavigateAway={onNavigateAway} />
            // <Row>

            //     <Col sm="12">
            //         <a href="#" onClick={()=> history.push("/mitglieder")}>{"<<<<"}</a>
            //     </Col>
            //     <Col sm="12">
            //         <img src={member?.pictureUrl} style={{height:"20rem"}}></img>
            //     </Col>
            //     <Col sm="12">
            //         <h3>{member?.name} </h3>

            //     </Col>


            //     <Col sm="12">
            //         <div className="zitat">{member?.zitat}</div>

            //     </Col>
            //     <Col sm="12">

            //         <div dangerouslySetInnerHTML={{__html:member?.content}}></div>

            //     </Col>


            // </Row>
             )}

<br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>



        </Container>
       
    )
}


export default MitgliedPage;