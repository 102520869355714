import { IndexedAccessType } from "typescript";

export interface Section{
    label:string;
    path:string;
    index:number;
    subsections?: Array<Section>;
    secondary?: boolean;
}




export const getSections = async () =>{
    const sections: Array<Section>= [
        {label:"Festival", path:"festival",index:1},
        {label:"Was ist Stimme X", path:"was-ist-stimmex", index:1, subsections:[
            {label:"Geschichte", path:"geschichte",index:2},
            {label:"Stimme X Heute", path:"stimmex-heute",index:2},
            {label:"Kooperationen", path: "kooperationen",index:3}
        ]},
        {label:"Verein", path:"verein", index:3, subsections:[
            {label:"Verein", path:"verein",index:1},
            {label:"Aufgabe und Ziele", path:"aufgaben-und-ziele",index:2},
            {label:"Satzung", path:"satzung",index:4},
            {label:"Vorstand", path:"vorstand",index:3}
        ]},
        {label:"Mitglieder", path:"mitglieder",index:4, subsections:[
            {label:"Mitglieder", path:"mitglieder",index:1, secondary:true},
            {label:"Mitglied werden", path:"mitglieder/s/mitglied-werden",index:2, secondary:true},
        ]},

        {label:"Save the Date", path: "save-the-date", index:5,  subsections:[
            {label:"Save the Date", path:"save-the-date",index:1, secondary:true},
            {label:"Archiv", path:"save-the-date/s/archiv",index:2, secondary:true},
        ]},

        
    ]

    return sections;
}