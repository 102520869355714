import { useState } from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { Container, Row } from "reactstrap";
import EventCard from "./EventCard";
import { firestore } from "./firebase";
import { StimmexEvent } from "./services/Events";
import firebase from "firebase/app";
import NewsItem from "./NewsItem";
import FolderSvg from './ordner-svg'
import TopTriabgle from "./TopTriangle";
import { transform } from "typescript";

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const News : React.FC<{ active: boolean }> = ({ active })=>{

    const [events] = useCollectionDataOnce<StimmexEvent>(firestore.collection("/events").where("start", ">", yesterday).orderBy("start", "asc").limit(10));
    const [internalActive, setInternalActive] = useState(false)



    return <div className={`content-news desktop-only content-shadow ${(active && internalActive && events && events.length>0) ? "news-active" : "news-inactive"} `}>
        <TopTriabgle id="news-triangle" style={{ display: "block", position: "absolute", top: "-31px",  left :"48px", width:"auto", height:"31px"}}  opacity="1" />
        <div className="" onClick={()=>setInternalActive(!internalActive)} style={{ position: 'absolute', padding:"5px", boxShadow: "-5px 0 5px -5px #55555533", backgroundColor: "#FFFFFFFF",left:0, top: "-31px", height:"31px", width: "50px" }}> <div className="news-text">News</div></div>

        <Container fluid>
            <Row className="flex-row flex-nowrap">
            {events && events.map(event => {
                
                if(event.start instanceof firebase.firestore.Timestamp){
                    const startTimestamp = event.start as firebase.firestore.Timestamp;
                    event.start = startTimestamp.toDate();
                }
                
                return <NewsItem event={event} key={event.id} />})}

            </Row>
        </Container>



    </div>
}

export default News;
