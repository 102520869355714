import { Col, Container, Row } from "reactstrap"
import trennlinie from './img/line-headline.svg'
import { useCollectionDataOnce, useCollectionOnce, useDocument, useDocumentData } from 'react-firebase-hooks/firestore';
import {firestore} from "./firebase";
import { Fragment } from "react";
import { StimmexText } from "./Types";
import festivalHeader from './img/festival-header.png'
import { left } from "@popperjs/core";




const Festival: React.FC = () => {

    const [subsections] = useCollectionDataOnce<StimmexText>(firestore.collection(`/texte`).where("section", "==", "festival").orderBy("index", "asc"));

    const getSubsection = (s: StimmexText, i:number) => {
        return (
            <Fragment key={`section-${i}`}>
                <Col sm="12"style={i>0? {marginTop:"90px"}: {}}>
                    
                    <h1 id={`sub-${s.path}`} className="subsection"> <img src={trennlinie} className="svg-icon svg-baseline "  /> {s.title}</h1>
                    
                    
                </Col>


                <Col sm="12">
                <img className="image-responsive" src="https://firebasestorage.googleapis.com/v0/b/stimmex-ec282.appspot.com/o/events%2Fimages%2FSTIMME_X_FESTIVAL_small.jpg?alt=media&token=043d5ee3-13b9-4f46-acb9-92841d92798c" style={{maxHeight:'500px', width:'auto', objectFit:'scale-down'}}></img>    
                <p> © Simone Scardovelli</p>
                
                {/* <img src="https://firebasestorage.googleapis.com/v0/b/stimmex-ec282.appspot.com/o/events%2Fimages%2Ffestival-log.png?alt=media&token=00e1224a-c8d5-42d5-b354-9ffb39b0bd6e" style={{width:"40%", height:"auto", float:"right", paddingLeft:"5px"}} /><p>Testing&nbsp;</p> */}

                <div dangerouslySetInnerHTML={{__html: s.content}}/>
                </Col>
            </Fragment>
        )
    }

    return (
        <Container >

            <Row>

                {subsections && subsections.map(getSubsection)}


            </Row>


            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

        </Container>)
}

export default Festival;