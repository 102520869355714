/* eslint-disable jsx-a11y/alt-text */
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { Col, Container, Row } from "reactstrap";
import { firestore, storage } from "./firebase";
import { Blogpost } from "./Types";
import trennlinie from './img/line-headline.svg'
import { useDownloadURL } from "react-firebase-hooks/storage";
import { useHistory } from "react-router-dom";
import { MouseEventHandler } from "react";

const  tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const Blog : React.FC<{setName: (name:string)=>void}> = ({setName})=>{

    setName("Blog")

    const [blogposts] = useCollectionDataOnce<Blogpost>(firestore.collection("/blogpost").where("publischDate", "<", tomorrow).orderBy("publischDate", "desc"))

    const BlogpostTeasers: React.FC<{blogpost :Blogpost }> = ({blogpost }) =>{

        const [imageUrl] = useDownloadURL(
            storage.ref(blogpost.picturePath)
        );

        const history = useHistory();

        const onClick :MouseEventHandler<HTMLDivElement> = (e:any)=>{
            history.push(`/s/blog/b/${blogpost.id}`)
        }

        return( 
        <Col sm="12" onClick={onClick} style={{cursor:"pointer"}}>
            
            <h3> <img src={trennlinie} className="svg-icon svg-baseline " /> {blogpost.title}</h3>
            <h5>{blogpost.publischDate?.toDate().toLocaleDateString("de-DE")} - <i>{blogpost.author}</i> </h5>

            {imageUrl && <img src={imageUrl} style={{height:"200px", width: "100%", objectFit:"cover"}}></img>}
            
            <div dangerouslySetInnerHTML={{__html:blogpost.teaser}}></div>

        
        </Col>
        
        )
    }


    return(

        <Container>
        <Row>
            <h1> <img src={trennlinie} className="svg-icon svg-baseline " /> Blog</h1>
            {blogposts && blogposts.filter(b=> b.online).map(blogpost => <BlogpostTeasers blogpost={blogpost}></BlogpostTeasers>)}
        </Row>

      </Container>
    )
}

export default Blog;