import { useEffect, useState } from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { Col, Container,  Row } from "reactstrap"
import { firestore } from "./firebase";
import firebase from "firebase/app";
import logos1 from './img/logos1.png'
import logos2 from './img/logos2.png'
import logos3 from './img/logos3.png'
import logos4 from './img/logos4.png'
import logos5 from './img/logos5.png'
import logos6 from './img/logos6.png'
import logos7 from './img/logos7.png'
import festivalLogo from './img/festival-logo.png'
import { useHistory } from "react-router-dom";


export type FestivalEvent = {
    start: firebase.firestore.Timestamp|Date;
    title: string;
    programmText:string;
    teaser: string;
    description: string;
    picturePath: string;
    photoCopyright:string;
    id?:string;
}

const datesAreOnSameDay = (first:Date, second:Date) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();


const friday = new Date('2022-05-13')
const saturday = new Date('2022-05-14')
const sunday = new Date('2022-05-15')

const getDay = (date:Date) =>{
    if(datesAreOnSameDay(date, friday)){
        return "friday";
    } else if(datesAreOnSameDay(date, saturday)){
        return "saturday";
    } else if(datesAreOnSameDay(date, sunday)){
        return "sunday";
    }

    return "invalid";
}

const Programm : React.FC<{onclick: (e:any)=>void}> = ({onclick})=>{
    const history = useHistory();
    const [events] = useCollectionDataOnce<FestivalEvent>(firestore.collection("/festivalevents").orderBy("start", "asc"))
    const [eventsByDay, setEventsByDay] = useState<{[day:string]: FestivalEvent[]}>();
    const [staticBg, setStaticBg] = useState(false)

    useEffect(()=>{
        if(events && events.length>0){

            const eventsByMonth = events.reduce((acc: { [day: string]: FestivalEvent[] }, event: FestivalEvent) => {
                if(event.start instanceof firebase.firestore.Timestamp){
                    event.start = event.start.toDate();
                }


                const day = getDay(event.start)
                if (acc[day]) {
                    acc[day].push(event)
                } else {
                    acc[day] = [event]
                }
                return acc;
            }, {})
            setEventsByDay(eventsByMonth)
        }
    }, [events])

    const  getEventElement = (events: FestivalEvent[]) =>  events.map(e=>
            <Col sm={12} style={{cursor:"pointer"}} onClick={ev=>{
                ev.stopPropagation();
                history.push(`/festival/s/${e.id}`)
            } }>
                {(e.start as Date)
                .toLocaleTimeString("de-DE", 
                        {hour: '2-digit',minute:'2-digit'})}
                <div className="programm-element" dangerouslySetInnerHTML={{__html: e.programmText}}></div>
                <br/>
            </Col>)
    const onContentScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
        const container = event.currentTarget;
        setStaticBg(container.scrollTop >150)

     }

    return <div className="programm" onClick={onclick} onScroll= {onContentScroll}>

        <div className="festival-static-bg" style={{visibility: staticBg ? 'visible': 'hidden'}} >
                <img src={festivalLogo} alt="" style={{width:'100%', height:'100%'}}/>

        </div>
    <Container  className="programm-container" fluid  >
        <Row>
            <Col className="programm-head" sm={12} >
                <h2>49h Stimme X <br/>
                Festival für zeitgenössisches<br/>
                Musiktheater
                </h2>

                <h2 style={{bottom:0, position:"absolute"}}>13. - 15. Mai <br/>
                Lichthof Theater<br/>
                Hamburg
                </h2>

            </Col>

        </Row>
        
        <br/>
                <br/>
        <Row>
        <Col  sm={12}>
            <h2>Festivalprogramm</h2>
            <br/><br/>
        </Col>

            <Col lg={4} className="programm-day-col">
                <Col sm={12}>
                    <h5>Freitag, 13.5.</h5>
                    <hr/>
                </Col>
                {eventsByDay && eventsByDay["friday"] && getEventElement(eventsByDay["friday"])}


            </Col>
            <Col lg={4} className="programm-day-col">
                <Col sm={12}>
                    <h5>Samstag, 14.5.</h5>
                    <hr/>
                </Col>
                {eventsByDay && eventsByDay["saturday"]&& getEventElement(eventsByDay["saturday"])}
            </Col>
            <Col lg={4} className="programm-day-col">
                <Col sm={12}>
                    <h5>Sonntag, 15.5.</h5>
                    <hr/>
                </Col>
                {eventsByDay && eventsByDay["sunday"] && getEventElement(eventsByDay["sunday"])}
            </Col>
            
            

            <Col sm={12}> 
                <hr/>
                gefördert durch:

            </Col>
            <Col sm={12}>
                <div className="flex-container">
                    <div className="flex-item">
                        <img src={logos1} alt="1" className="img-responsive" />
                    </div>
                    <div className="flex-item">
                        <img src={logos6} alt="1" className="img-responsive"  style={{filter:'invert(100%)'}}/>
                    </div>
                    <div className="flex-item">
                        <img src={logos2}alt="2" className="img-responsive" />
                    </div>
                    <div className="flex-item">
                        <img src={logos3} alt="3" className="img-responsive" />
                    </div>
                    <div className="flex-item">
                        <img src={logos7}alt="5"className="img-responsive" />
                    </div>
                </div>
            </Col>

            <Col sm={12}> 
                
                Kooperationspartner:

            </Col>
            <Col sm={12}>
                <div className="flex-container">
                    <div className="flex-item">
                        <img src={logos4} alt="4" className="img-responsive" />
                    </div>
                    <div className="flex-item">
                        <img src={logos5}alt="5"className="img-responsive" />
                    </div>
                </div>
            </Col>

            <Col sm={12}>
            <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
            </Col>

            
           
            
        </Row>



    </Container>
    
    
    </div>
}


export default Programm


