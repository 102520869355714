
import { useHistory, useParams } from "react-router-dom";
import { Card, CardImg, CardImgOverlay, CardText, Col,  Row } from "reactstrap";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";

import { firestore, storage } from "./firebase";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { Blogpost } from "./Types";
import trennlinie from './img/line-headline.svg'


const BlogpostElement: React.FC<{ setName: (name: string) => void }> = ({setName}) => {

    const {postId} = useParams<{ postId: string }>();
    const history = useHistory();
    
    const [blogpost] = useDocumentDataOnce<Blogpost>(firestore.doc(`/blogpost/${postId.trim()}`))

    const [imageUrl] = useDownloadURL(
        storage.ref(blogpost?.picturePath)
    );

    setName("Blog")


    return (<div>

        <Row>
        <Col sm="12">
                <a href="#" onClick={() => history.push("/s/blog")}>{"<<<<"}</a>
            </Col>

        <Col sm="12">
                <h1><img src={trennlinie} className="svg-icon svg-baseline " />{blogpost?.title}</h1>

            </Col>
            <Col sm="12">
                <h5>{blogpost?.publischDate?.toDate().toLocaleDateString("de-DE")}  - <i>{blogpost?.author}</i></h5>

            </Col>



            <Col sm="12">
            <Card style={{ width:"100%" }} >
                <CardImg src={imageUrl} className="h-100" style={{objectFit: "cover"}} ></CardImg>
                { blogpost && blogpost.photoCopyright && <CardImgOverlay className=" h-100 p-0 d-flex flex-column justify-content-end"><CardText className=" copyright p-1"><strong> © {blogpost.photoCopyright} </strong></CardText></CardImgOverlay>}
            </Card>

            {/* <img src={member?.pictureUrl} style={{height:"290px", width:"250px", objectFit: "cover", marginTop:"30px", marginBottom:"30px" }}></img> */}
        </Col>


            <Col sm="12">
                <div dangerouslySetInnerHTML={{__html: blogpost? blogpost.content : ""}}></div>

                <br/><br/><br/><br/><br/><br/><br/><br/>




            </Col>


        </Row>




    </div>)
}

export default BlogpostElement;


