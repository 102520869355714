import { useState } from "react"
import { Route, Switch, useHistory } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import Datenschutz from "./Datenschutz"
import Impressum from "./Impressum"
import MitgliedPage from "./MitgliedPage"
import MitgliedWerden from "./MitgliedWerden"
import FolderSvg from './ordner-svg'
import Event from"./Event"
import Archiv from "./Archiv"
import Blog from "./Blog"
import BlogpostElement from "./Blogpost"
import FestivalEvent from "./FestivalEvent"

const SecondaryContent: React.FC<{ active: boolean }> = ({ active }) => {

  const [name, setName] = useState("Mitglied");
  const history = useHistory()

  const [deactivating, setDeactivating] = useState(false);


  const onNavigateAway = (targetPath: string) => {
    setDeactivating(true);
    setTimeout(() => {
      history.push(targetPath);
      setDeactivating(false);
    }, 500)

  }



  return (


    <div className={`content-secondary content-shadow ${(active && !deactivating) ? "secondary-active" : "secondary-inactive"} `} >
      <FolderSvg style={{ display: "block", position: "absolute", left: "-20px", width: "20px", bottom: `${3 * 20 + 4.95}vh` }} opacity="1" />


      <div className="" style={{ position: 'absolute', boxShadow: "-5px 0 5px -5px #55555533", backgroundColor: "#FFFFFFFF", bottom: 0, left: "-20px", height: `${3 * 20 + 5}vh`, width: "20px" }}> <div className="rotatedText">{name}</div></div>

      <div id={`content-secondary`} className="overflow-auto content-container"  >
        <Switch>
          <Route path="/mitglieder/s/m/:mitgliedId">
            <MitgliedPage onNameReady={n => setName(n)} onNavigateAway={onNavigateAway} />
          </Route>
          <Route path="/verein/s/v/:mitgliedId">
            <MitgliedPage onNameReady={n => setName(n)} onNavigateAway={onNavigateAway} backPath="/verein" backLable="Vorstand" />
          </Route>
          <Route path="/mitglieder/s/mitglied-werden"  >
            <MitgliedWerden setLable={setName} />

          </Route>

          <Route path="/s/impressum"  >
           <Impressum setName={setName} />
          </Route>

          <Route path="/s/datenschutz"  >
            <Datenschutz setName={setName} />

          </Route>

          <Route path="/s/blog/b/:postId"  >
            <BlogpostElement setName={setName} />
          </Route>

          <Route path="/s/blog"  >
            <Blog setName={setName} />
          </Route>



          <Route path="/save-the-date/s/archiv">
                <Archiv setName={setName} />
          </Route>

          <Route path="/save-the-date/s/:eventId">
                <Event setName={setName} />
            </Route>

          <Route path="/festival/s/:eventId">
                <FestivalEvent setName={setName} />
          </Route> 
        </Switch>
      </div>
      <div className="right-backround" ></div>

    </div>)
}

export default SecondaryContent;