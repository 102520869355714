import React, { useEffect,  useState, Fragment} from 'react';
import TopTriabgle from './TopTriangle';
import subvarLine from './img/line-subbar-grey.svg'
import topbarlinie from './img/line-topbar-grey.svg'
import topbarlinieActive from './img/line-topbar-black.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import stimmexLogo from './img/logo_anim.gif'
import FolderSvg from './ordner-svg'
import { getSections, Section } from './services/Sections'
import {  useHistory, useLocation } from 'react-router-dom'

import './App.css';
import {  Collapse, Container,  Nav, Navbar, NavbarBrand,  NavbarToggler, NavItem, NavLink, } from 'reactstrap';
import Mitglieder from './Mitglieder';
import SaveTheDate from './SaveTheDate';
import WasIstStimmex from './WasIstStimmeX';
import Verein from './Verein';
import Links from './Links';
import { scrollToElm } from './scrollHelper';
import SecondaryContent from './SecondaryContent';
import News from './News';
import Programm from './Programm';
import Festival from './Festival';

export type BgModes = "red" | "yellow" |"turk" | "purple";



const App: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  // const [encodedSvgUrls, setEncodedSvgURl] = useState<{ [key: string]: string }>({});
  // const [activeContent, setActiveContent] = useState("");
  // const [doneRenderingSvgs, setdoneRenderingSvg] = useState<{ [key: string]: boolean }>({});
  const [sections, setSections] = useState<Section[]>([]);
  const history = useHistory();
  const location = useLocation();

  const [subnavbarLefts, setSubnavbarlefts] = useState<any>({});
  const [activeSubsection, _setActiveSubsection] = useState("geschichte")

  const setActiveSubsection = (s:string)=>{
    // setDeactivating(true);
    // setTimeout(() => {
    //   setDeactivating(false);
    // }, 500)
    _setActiveSubsection(s)

  }

  // const [deactivating, setDeactivating] = useState(false);











  const activeContent = location.pathname.split('/')[1];

  const secondaryActive = location.pathname.includes("/s/");

  useEffect(() => {
    getSections().then(s => setSections(s));


  }, [])

  // useEffect(() => {

  //   if (activeContent) {
  //     const subsections = sections.find(s => s.path == activeContent)?.subsections;
  //     if (subsections) {
  //       setActiveSubsection(subsections[0].path)
  //     }
  //   }

  //   setTimeout(updtaeSubnavbarLeft, 300);

  // }, [activeContent, sections])

  const navigateTo = (path: string) => {
    setOpen(false);
    history.push(path);
  }


  const setSubsectionInitial = (contentId: string) => {
    const activeContentElement = document.getElementById(`content-${contentId}`);

    if (activeContentElement) {

      activeContentElement.scrollTop = 0;
      const subsections = Array.prototype.slice.call(activeContentElement.querySelectorAll<HTMLElement>(".subsection"));
      if (subsections.length <= 1) {
        return;
      }


      const subsectionsSort = subsections.map((s) => {

        const pPos = activeContentElement.getBoundingClientRect();
        const cPos = s.getBoundingClientRect();
        const offset = cPos.top - pPos.top;
        return [offset, s];

      }).sort(([offset1, s], [offset2, s2]) => offset1 - offset2)

      const [_, targetElement] = subsectionsSort[0];

      const sectionId = (targetElement.getAttribute("id") as string).substring(4);

      if (sectionId) {
        setActiveSubsection(sectionId);
      }

    }
  }

  const onNavItemClicked = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, path: string) => {

    if (path === activeContent) {
      navigateTo(`/`)
    } else {
      navigateTo(`/${path}`)
    }

    const clientRect = e.currentTarget.getBoundingClientRect();
    const newsubnavbarLefts = { ...subnavbarLefts }
    if (newsubnavbarLefts[path] !== clientRect.left) {
      newsubnavbarLefts[path] = clientRect.left;
      setSubnavbarlefts(newsubnavbarLefts);
    }

    setSubsectionInitial(path)



  }





  const getNavItem = (s: Section) => {


    return (<Fragment key={s.path}>
      {!s.subsections ?
        <NavItem>
          <NavLink id={`sec-${s.path}`} className={s.path === activeContent ? "active" : ""} href="#" onClick={(e) => onNavItemClicked(e, s.path)}>{s.label}</NavLink>
        </NavItem>

        :

        <>
          <NavItem >
            <NavLink id={`sec-${s.path}`} className={s.path === activeContent ? "active" : ""} href="#" onClick={(e) => onNavItemClicked(e, s.path)}> {s.label}</NavLink>
          </NavItem>


        </>


      }

      <span className="nav-separator"> <img src={s.path === activeContent ? topbarlinieActive : topbarlinie} className="svg-icon separator-main" style={{ height: "20px" }} /> </span>


    </Fragment>)
  }

  const createSubsections = (s: Section) => {

    const scrollToSubsection = (sub: Section) => {
      const parent = document.getElementById(`content-${s.path}`);
      const subsection = document.getElementById(`sub-${sub.path}`);
      if (parent && subsection) {
        scrollToElm(parent, subsection, 1);
      }

    }

    return (
      <Fragment key={s.path}>
      {s.subsections && 
          <div className={`subnavbar ${s.path === activeContent ? "active" : ""}`} style={{ left: subnavbarLefts[s.path] }}>
            <TopTriabgle style={{ display: "block", position: "absolute", top: "0", left: "-16px", width: "auto", height: "31px" }} opacity="1" />


            <Navbar color="light" light expand="lg" style={{ height: "100%" }}>

              <Nav className="mr-auto" navbar>
                {s.subsections.map(sub => {
                  return (
                    <Fragment key={sub.path}>
                    <NavItem >
                      <NavLink className={sub.secondary? `${sub.path === location.pathname.substring(1) ? "active" : ""}` :`${sub.path === activeSubsection ? "active" : ""}`} href="#" onClick={sub.secondary ? ()=> navigateTo(`/${sub.path}`) :() => scrollToSubsection(sub)}> {sub.label}</NavLink>
                    </NavItem>
                    <span className="nav-separator"> <img src={subvarLine} style={{height:"10px"}} /> </span>
                    </Fragment>
                    
                    )

                })}

              </Nav>


            </Navbar>

          </div>
        }


      </Fragment>
    )
  }

  const toggleMobileMenu = () => {
    setOpen(!isOpen);
  }

  const onActiveSubsectionChanged = (activateSubsection: string) => {
    setActiveSubsection(activateSubsection);
  }

  const getSectionContent = (s: Section) => {
    switch (s.path) {
      case "festival":
        return <Festival />

      case "was-ist-stimmex":
        return <WasIstStimmex onActiveSubsectionChanged={onActiveSubsectionChanged} />

      case "save-the-date":
        return <SaveTheDate />

      case "mitglieder":
        return <Mitglieder />

      case "verein":
        return <Verein />

      case "links":
        return <Links />



    }
  }

  const onContentScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
    const container = event.currentTarget;
    const subsections = Array.prototype.slice.call(container.querySelectorAll<HTMLElement>(".subsection"));
    if (subsections.length <= 1) {
      return;
    }


    const subsectionsSort = subsections.map((s) => {
      const pPos = container.getBoundingClientRect();
      const cPos = s.getBoundingClientRect();
      const offset = cPos.top - pPos.top;
      return [offset, s];

    }).sort(([offset1, s], [offset2, s2]) => offset1 - offset2)

    let firstVisibleTitleIndex = subsectionsSort.findIndex(([offset, s]) => offset > 0);
    if (firstVisibleTitleIndex < 0) {
      firstVisibleTitleIndex = subsectionsSort.length - 1;
    }
    const [firstVisibleTitleOffset, firstVisibleTitle] = subsectionsSort[firstVisibleTitleIndex];
    const targetIndex =
      (firstVisibleTitleOffset < container.clientHeight / 3) ?
        firstVisibleTitleIndex :
        firstVisibleTitleIndex > 0 ?
          firstVisibleTitleIndex - 1
          : 0;

    const [_, targetElement] = subsectionsSort[targetIndex];

    const sectionId = (targetElement.getAttribute("id") as string).substring(4);

    if (sectionId) {
      setActiveSubsection(sectionId);
    }

  }

  const getSectionContainer = (s: Section) => {
    return (


      <div  key={s.path}  className={`content content-shadow ${activeContent === s.path ? "content-active" : "content-inactive"}  `} >
        <FolderSvg style={{ display: "block", position: "absolute", left :"-20px", width:"20px",  bottom : `${s.index * (Math.min(20, 95 / sections.length)) +4.95}vh`}}  opacity="1"/>
        
        
        <div onClick={()=>navigateTo(`/${s.path}`)} className="" style={{ position: 'absolute' ,  backgroundColor: "#FFFFFFFF", bottom: 0, left: "-20px", height: `${s.index * (Math.min(20, 95 / sections.length)) +5}vh` , width: "20px" }}> <div className="rotatedText">{s.label}</div></div>
        
        <div id={`content-${s.path}`} className="overflow-auto content-container" onScroll= {onContentScroll}   >
          {getSectionContent(s)}
        </div>
        <div className="right-backround" ></div>

      </div>)
  }



  // const onSoundmachineModeChange = (newMode: BgModes) => {
  //   const backVideo = videoMap[activeBgMode][activeBgMode].current;
  //   const transVideo = videoMap[activeBgMode][newMode].current;
  //   const newBgVideo = videoMap[newMode][newMode].current;


  //   if (backVideo && transVideo && newBgVideo && !mobile()) {
  //     setTransitioning(true);
  //     setActiveBgMode(newMode);

  //     backVideo.loop = false;
  //     transVideo.load();
  //     transVideo.currentTime = 0;
  //     backVideo.playbackRate=1.8;

  //     backVideo.onended = (e) => {
  //       backVideo.onended =null;
  //       backVideo.playbackRate=1;
  //       transVideo.classList.remove("hidden-vid");
  //       transVideo.classList.add("visible-vid");
  //       transVideo.play();

  //       setTimeout(()=>{
  //         backVideo.classList.add("hidden-vid");
  //         backVideo.classList.remove("visible-vid");
  //         backVideo.currentTime=0;

  //         newBgVideo.classList.remove("hidden-vid");
  //         newBgVideo.classList.add("visible-vid");
  //         newBgVideo.load();
  //         newBgVideo.pause();
  //         newBgVideo.currentTime = 0;
  //       },100)


  //       transVideo.onended = (e) => {
  //         transVideo.onended=null;
  //         transVideo.classList.add("hidden-vid")
  //         transVideo.classList.remove("visible-vid");
  //         newBgVideo.loop = true;
  //         newBgVideo.play();
  //         setTransitioning(false);
          

  //       }
  //     }

  //   }

  // }


  return (
    < >
      <Navbar color="light" light expand="lg">
        <NavbarBrand href="#" onClick={e => navigateTo("/")} ><img src={stimmexLogo} alt="Stimme X"></img></NavbarBrand>
        <NavbarToggler onClick={toggleMobileMenu} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>

            {sections.map(getNavItem)}


            <NavItem>
              <NavLink id={`sec-blog`} className={"blog" === activeContent ? "active" : ""} href="#" onClick={(e) => onNavItemClicked(e, "s/blog")}>Blog</NavLink>
            </NavItem>

            <span className="nav-separator"> <img src={"blog" === activeContent ? topbarlinieActive : topbarlinie} className="svg-icon separator-main" style={{ height: "20px" }} /> </span>

            <NavItem>
              <NavLink id={`login`}href="#" onClick={(e) => window.open("https://stimmex-cm.web.app/")}>Login</NavLink>
            </NavItem>
            

            <NavItem>
              <NavLink id={`sec-mitglied-werden`} className={`mobile-menu-only ${"mitglied-werden" === activeContent ? "active" : ""}`} href="#" onClick={(e) => onNavItemClicked(e, "mitglieder/s/mitglied-werden")}><b>Mitglied werden</b></NavLink>
            </NavItem>

          </Nav>

        </Collapse>
      </Navbar>

      {sections.map(createSubsections)}


    

    <FontAwesomeIcon icon={faFacebook} onClick={()=> window.open("https://www.facebook.com/StimmeX")} style={{position:"absolute", "top":"7vh", "left": "10px", height:"3vh", width:"auto", zIndex:1, color:"white", cursor:"pointer"}}/>
    <FontAwesomeIcon icon={faInstagram} onClick={()=> window.open("https://www.instagram.com/stimme_x/")} style={{position:"absolute", "top":"12vh", "left": "10px", height:"3vh", width:"auto", zIndex:1, color:"white", cursor:"pointer"}}/>


      <Programm onclick={e => navigateTo("/")}  ></Programm>

      {sections.slice().reverse().map(getSectionContainer)}

      <SecondaryContent active={secondaryActive} />

      <News active={!activeContent} />




      <footer className="footer fixed-bottom">
        <Container fluid>
          <div className="float-left desktop-only">Stimme X Zeitgenössisches Musiktheater Norddeutschland</div>
          <div className="float-right">
            <ul>
              <li> <a href="#" onClick={() => navigateTo("/s/datenschutz")}>Datenschutzerklärung</a></li>
              <li> <a href="#" onClick={() => navigateTo("/s/impressum")} >Impressum </a>  </li>


            </ul>

          </div>


        </Container>

      </footer>

    </>
  );
}

export default App;
