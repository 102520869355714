import React, { useState } from "react";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import trennlinie from './img/line-headline.svg'
import { firestore } from "./firebase"


const MitgliedWerden: React.FC<{ setLable: (name: string) => void }> = ({ setLable }) => {
    setLable("Mitglied werden");
    const [charachtersRemaining, setCharachterCount] = useState(1000);
    const [beschreibung, setBeschreibung] = useState("");

    const [succesful, setSuccessful] = useState(false);

    const [name, setName] = useState("");
    const [nachname, setNachname] = useState("");
    const [strasse, setStrasse] = useState("");
    const [nr, setNr] = useState("");
    const [plz, setPlz] = useState("");
    const [ort, setOrt] = useState("");
    const [land, setLand] = useState("");
    const [bundesland, setBundesland] = useState("");

    const [telefon, setTelefon] = useState("");
    const [email, setEmail] = useState("");
    const [homepage, setHomepage] = useState("");
    const [berufsbezeichnung, setBerufsbezeichnung] = useState("");




    const onBeschreibungInput: React.FormEventHandler<HTMLInputElement> = (event) => {
        setBeschreibung(event.currentTarget.value)
        setCharachterCount(1000 - event.currentTarget.value.length);
    }

    const onChangeFor = (setter: (value: string) => void) => {
        const result: React.ChangeEventHandler<HTMLInputElement> = (event) => {
            const value = event.currentTarget.value;
            setter(value);
        }
        return result;
    }

    const onSend: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        firestore.collection("/mail").doc("Memebrship-Request-" + new Date().toISOString()).set({
            to: "kontakt@stimmex.de",
            replyTo: email,
            message: {
                subject: `New Memebership Request ${name} ${nachname} `,
                html: `
                <table>
                <tr>
                    <td>Name</td>
                    <td>${name}</td>
                </tr>
                <tr>
                    <td>Nachname</td>
                    <td>${nachname}</td>
                </tr>
            
                <tr>
                    <td>Straße</td>
                    <td>${strasse}</td>
                </tr>
            
        
                <tr>
                    <td>Nr</td>
                    <td>${nr}</td>
                </tr>
            
            
                <tr>
                    <td>PLZ</td>
                    <td>${plz}</td>
                </tr>
                <tr>
                    <td>Ort</td>
                    <td>${ort}</td>
                </tr>
            
            
                <tr>
                    <td>Land</td>
                    <td>${land}</td>
                </tr>
                <tr>
                    <td>Bundesland</td>
                    <td>${bundesland}</td>
                </tr>
            
            
                <tr>
                    <td>Telefon</td>
                    <td>${telefon}</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>${email}</td>
                </tr>
            
            
                <tr>
                    <td>Homepage</td>
                    <td>${homepage}</td>
                </tr>
                <tr>
                    <td>Berufsbezeichnung</td>
                    <td>${berufsbezeichnung}</td>
                </tr>
            
            
                <tr>
                    <td>Beschribung</td>
                    <td>${beschreibung}</td>
                </tr>
            
            
            
            </table>
                
                `
            }
        }).then(() => {
            setSuccessful(true);
        })
    }

    return (

        <Container  >
            {!succesful &&
                <Form inline className="desktop-only" >

                    <Row form  >

                        <Col sm="12">
                            <h1 id="sub-mitglieder" style={{ marginBottom: "60px" }}> <img src={trennlinie} className="svg-icon svg-baseline " /> Mitglied werden</h1>
                        </Col>

                        <Col sm={6}  >

                            <Row form>
                                <Col sm={3}>
                                    <Label for="name" >Name</Label>
                                    <Label for="strasse">Straße</Label>
                                    <Label for="plz">PLZ</Label>
                                    <Label for="land">Land</Label>
                                    <Label for="telefon">Telefon</Label>
                                    <Label for="email">E-Mail</Label>

                                </Col>
                                <Col sm={9}>
                                    <FormGroup inline >
                                        <Input tabindex="1" value={name} onChange={onChangeFor(setName)} type="text" name="name" id="name" />
                                    </FormGroup>
                                    <FormGroup inline >
                                        <Input tabindex="3" value={strasse} onChange={onChangeFor(setStrasse)} type="text" name="strasse" id="strasse" />
                                    </FormGroup>
                                    <FormGroup inline >
                                        <Input tabindex="5" value={plz} onChange={onChangeFor(setPlz)} type="text" name="plz" id="plz" />
                                    </FormGroup>
                                    <FormGroup inline >
                                        <Input tabindex="7" value={land} onChange={onChangeFor(setLand)} type="text" name="land" id="land" />
                                    </FormGroup>
                                    <FormGroup inline >
                                        <Input tabindex="9" value={telefon} onChange={onChangeFor(setTelefon)} type="text" name="telefon" id="telefon" />
                                    </FormGroup>
                                    <FormGroup inline >
                                        <Input tabindex="10" value={email} onChange={onChangeFor(setEmail)} type="email" name="email" id="email" />
                                    </FormGroup>

                                </Col>
                            </Row>

                        </Col>
                        <Col sm={6}  >

                            <Row form>
                                <Col md={3}>
                                    <Label for="nachname">Nachname</Label>
                                    <Label for="nr">Nr.</Label>
                                    <Label for="ort">Ort</Label>
                                    <Label for="bundesland">Bundesland</Label>
                                </Col>
                                <Col sm={9}>
                                    <FormGroup inline >
                                        <Input tabindex="2" type="text" value={nachname} onChange={onChangeFor(setNachname)} name="nachname" id="nachname" />
                                    </FormGroup>
                                    <FormGroup inline >
                                        <Input tabindex="4" value={nr} onChange={onChangeFor(setNr)} type="text" name="nr" id="nr" />
                                    </FormGroup>
                                    <FormGroup inline >
                                        <Input tabindex="6" value={ort} onChange={onChangeFor(setOrt)} type="text" name="ort" id="ort" />
                                    </FormGroup>
                                    <FormGroup inline >
                                        <Input tabindex="8" value={bundesland} onChange={onChangeFor(setBundesland)} type="text" name="bundesland" id="bundesland" />
                                    </FormGroup>

                                </Col>
                            </Row>

                        </Col>
                    </Row>



                </Form>

            }

            {!succesful &&
                <Form className="mobile-only" >

                    <Row form  >

                        <Col sm="12">
                            <h1 id="sub-mitglieder" style={{ marginBottom: "60px" }}> <img src={trennlinie} className="svg-icon svg-baseline " /> Mitglied Werden</h1>
                        </Col>

                        <Col sm={12}  >

                            <FormGroup>
                                <Label for="name" >Name</Label>
                                <Input tabindex="1" value={name} onChange={onChangeFor(setName)} type="text" name="name" id="name" />
                            </FormGroup>
                            <FormGroup  >
                                <Label for="nachname">Nachname</Label>
                                <Input tabindex="2" type="text" value={nachname} onChange={onChangeFor(setNachname)} name="nachname" id="nachname" />
                            </FormGroup>
                            <FormGroup  >
                                <Label for="strasse">Straße</Label>
                                <Input tabindex="3" value={strasse} onChange={onChangeFor(setStrasse)} type="text" name="strasse" id="strasse" />
                            </FormGroup>

                            <FormGroup  >
                                <Label for="nr">Nr.</Label>
                                <Input tabindex="4" value={nr} onChange={onChangeFor(setNr)} type="text" name="nr" id="nr" />
                            </FormGroup>
                            <FormGroup  >
                                <Label for="plz">PLZ</Label>
                                <Input tabindex="5" value={plz} onChange={onChangeFor(setPlz)} type="text" name="plz" id="plz" />
                            </FormGroup>
                            <FormGroup  >
                                <Label for="ort">Ort</Label>
                                <Input tabindex="6" value={ort} onChange={onChangeFor(setOrt)} type="text" name="ort" id="ort" />
                            </FormGroup>
                            <FormGroup  >
                                <Label for="land">Land</Label>
                                <Input tabindex="7" value={land} onChange={onChangeFor(setLand)} type="text" name="land" id="land" />
                            </FormGroup>
                            <FormGroup   >
                                <Label for="bundesland">Bundesland</Label>
                                <Input tabindex="8" value={bundesland} onChange={onChangeFor(setBundesland)} type="text" name="bundesland" id="bundesland" />
                            </FormGroup>
                            <FormGroup  >
                                <Label for="telefon">Telefon</Label>
                                <Input tabindex="9" value={telefon} onChange={onChangeFor(setTelefon)} type="text" name="telefon" id="telefon" />
                            </FormGroup>
                            <FormGroup  >
                                <Label for="email">E-Mail</Label>
                                <Input tabindex="10" value={email} onChange={onChangeFor(setEmail)} type="email" name="email" id="email" />
                            </FormGroup>
                        </Col>
                    </Row>

                </Form>}



            {!succesful &&
                <Form >
                    <Row form  >
                        <Col xs={12}>
                            <FormGroup  >
                                <Label for="homepage">Homepage</Label>
                                <Input tabindex="11" value={homepage} onChange={onChangeFor(setHomepage)} type="text" name="homepage" id="homepage" />
                            </FormGroup>
                            <FormGroup  >
                                <Label for="berufsbezeichnung">Berufsbezeichnung</Label>
                                <Input tabindex="12" value={berufsbezeichnung} onChange={onChangeFor(setBerufsbezeichnung)} type="text" name="berufsbezeichnung" id="berufsbezeichnung" />
                            </FormGroup>

                            <FormGroup  >
                                <Label for="beschreibung">
                                    Für ordentliche Mitglieder: <br />
                                    Beschreibe deine Künstleriche Arbeit (maximal 1000 Zeichen)

                                </Label>
                                <Input tabindex="13" style={{ height: "15em" }} maxLength={1000} value={beschreibung} onInput={onBeschreibungInput} type="textarea" name="beschreibung" id="beschreibung" />
                                <p>Noch {charachtersRemaining} Zeichen übrig.</p>
                            </FormGroup>


                        </Col>

                    </Row>
                </Form>}
            {!succesful &&
                <Button tabindex="14" onClick={onSend}>Abschicken</Button>}

            {succesful &&

                <Row>

                    <Col sm="12">
                        <h1 id="sub-mitglieder" style={{ marginBottom: "60px" }}> <img src={trennlinie} className="svg-icon svg-baseline " /> Mitglied Werden</h1>
                    </Col>

                    <Col sm="12">
                        <p>Danke für deine Bewerbung. Wir werden dich in kurze kontaktieren.</p>
                    </Col>
                </Row>
            }

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

        </Container>
    )
}

export default MitgliedWerden;