import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { Col, Container, Row } from "reactstrap"
import { firestore } from "./firebase";
import Mitglied from "./Mitglied";
import trennlinie from './img/line-headline.svg';
import pdfSvg from'./img/pdf.svg'
import { Member } from "./services/Mitglieder";
import VorstandListElement from "./VorstandListElement";
import { Fragment } from "react";
import { StimmexText } from "./Types";



const Verein: React.FC = () => {

    const [subsections] = useCollectionDataOnce<StimmexText>(firestore.collection(`/texte`).where("section", "==", "Verein").orderBy("index", "asc"));
    const [vorstandMitglieder ] = useCollectionDataOnce<Member>(firestore.collection(`/sections/mitglieder/mitglieder`).orderBy("index"));

    const getSubsection = (s: StimmexText, i:number) => {
        if(s.id==="vorstand"){
            return;
        }

        return (
            <Fragment key={`section-${i}`}>
                <Col  sm="12"style={i>0? {marginTop:"90px"}: {}}>
                    
                    <h1 id={`sub-${s.path}`} className="subsection"> <img src={trennlinie} className="svg-icon svg-baseline" /> {s.title}</h1>
                    
                </Col>
                <Col sm="12"  >
                <div dangerouslySetInnerHTML={{__html: s.content}}/>
                </Col>
            </Fragment>

            
        )
    }

    return (
        <Container>

            <Row>

                {subsections && subsections.map(getSubsection)}



            </Row>

            <Row>

            <Col sm="12" style={{marginTop:"90px"}}>
            <h1 id={`sub-satzung`} className="subsection"> <img src={trennlinie} className="svg-icon " /> Die Satzung</h1>

            <p>Alle informationen zur Sazung finden Sie in folgender PDF:</p>
            <p > <a href="#" className="back-link" onClick={()=> window.open('https://firebasestorage.googleapis.com/v0/b/stimmex-ec282.appspot.com/o/documents%2FStimme%20X%20e.V.%20Satzung%20Original.pdf?alt=media&token=741e4411-c5ed-4241-976e-a390f9dd7cba')}> <img style={{height:"80px", width:"auto"}} src={pdfSvg} className="svg-icon " /> Download </a></p>

            </Col>





            <Col sm="12" style={{marginTop:"90px"}}>
            <h1 id={`sub-vorstand`} className="subsection"> <img src={trennlinie} className="svg-icon " /> Der Vorstand</h1>

            </Col>

            {vorstandMitglieder && vorstandMitglieder.map((m: Member)=> (<VorstandListElement key={m.id} member={m}  />))}

            </Row>

            


            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

        </Container>)
}

export default Verein;