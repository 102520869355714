import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { Col, Container, Row } from "reactstrap"
import { firestore } from "./firebase";



const Links: React.FC = () => {
    const [linksSection]= useDocumentDataOnce<{content:string}>(firestore.doc("/sections/Links"))

    return (
        <Container>

            <Row>
                <Col sm="12">
                    {linksSection && <div dangerouslySetInnerHTML={{__html: linksSection.content}} /> }
                    
                </Col>

            </Row>


            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

        </Container>)
}

export default Links;