import { useHistory, useParams } from "react-router-dom";
import { Card, CardImg, CardImgOverlay, CardText, Col, Container, Row } from "reactstrap";
import { getCollectives } from "./services/Mitglieder";

import placeholderColl from './img/800x180.png'
import { useDocumentDataOnce, useDocumentOnce } from "react-firebase-hooks/firestore";
import { IFestivalEvent } from "./services/Events";
import { firestore, storage } from "./firebase";
import { useDownloadURL } from "react-firebase-hooks/storage";


const FestivalEvent: React.FC<{ setName: (name: string) => void }> = ({setName}) => {
    const { eventId } = useParams<{ eventId: string }>();
    const history = useHistory();
    const [event] = useDocumentDataOnce<IFestivalEvent>(firestore.doc(`/festivalevents/${eventId}`))

    const [imageUrl] = useDownloadURL(
        storage.ref(event?.picturePath)
    );

    setName(event?.start.toDate().toLocaleDateString("de-DE"))

    const isEmptyOrSpaces = (str:string) => str === null || str.match(/^ *$/) !== null;
    


    return (<div>

        <Row>

            <Col sm="12">
                <a href="#" onClick={() => history.push("/")}>{"<<<<"}</a>
            </Col>
            {/* <Col sm="12">
                <img src={imageUrl} style={{ width:"100%" }}></img>
               

            </Col> */}

            <Col sm="12">
            {/* <Card style={{cursor:"default" }} >
                <CardImg src={imageUrl} className="h-100" style={{objectFit: "scale-down", maxHeight:'400px'}} ></CardImg>
                { event && event.photoCopyright && <CardImgOverlay className=" h-100 p-0 d-flex flex-column justify-content-end"><CardText className=" copyright p-1"><strong> © {event.photoCopyright} </strong></CardText></CardImgOverlay>}
            </Card>
            <br/> */}

            <img className="image-responsive" src={imageUrl} style={{maxHeight:'500px', width:'auto', objectFit:'scale-down'}}></img>
            {event && event.photoCopyright && <p className="copyright"> © {event.photoCopyright}</p>}
            <br/>

            {/* <img src={member?.pictureUrl} style={{height:"290px", width:"250px", objectFit: "cover", marginTop:"30px", marginBottom:"30px" }}></img> */}
        </Col>
            <Col sm="12">
                <h3>{event?.title}</h3>
                {event && event.teaser && !isEmptyOrSpaces(event.teaser) && <div className="subtitle" dangerouslySetInnerHTML={{__html: event.teaser}} ></div>}
            </Col>
            <Col sm="12">
                <h5>{event?.start.toDate().toLocaleDateString("de-DE")}  {event?.start.toDate().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} </h5>

            </Col>

            <Col sm="12">
                <div dangerouslySetInnerHTML={{__html: event? event.description : ""}}></div>
                {event?.linkUrl && <p><a onClick={()=>window.open(event?.linkUrl)} href="#">{event?.linkText ? event?.linkText  : event.linkUrl }</a></p>}
                <br/><br/><br/><br/><br/><br/><br/><br/>




            </Col>


        </Row>




    </div>)
}

export default FestivalEvent;


